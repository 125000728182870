.homeButton {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0%);
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
        "Lucida Sans", Arial, sans-serif;
}

.headshot {
    display: block;
    margin-left: auto;
    margin-right: auto;
    object-fit: cover;
    width: 75%;
    /* height: 23vh; */
    border-radius: 50%;
}

.icons {
    margin-top: 5%;
    position: relative;
    top: 5%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

h1 {
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
        "Lucida Sans", Arial, sans-serif;
}

.sidebardiv {
    margin: auto;
}
