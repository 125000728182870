.base {
    background: url("../../Images/me.jpg");
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    color: white;
    font-size: large;
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
        "Lucida Sans", Arial, sans-serif;
}

.base p {
    opacity: 0;
}

.arrow {
    cursor: pointer;
    opacity: 0;
    color: white;
    position: relative;
    font-size: xx-large;
    top: 73vh;
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-delay: 2s;
}

.base h1 {
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-delay: 0s;
    position: relative;
    top: 6%;
    text-shadow: black 0px 0px 10px;
}

.base p {
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    position: relative;
    top: 3%;
    animation-delay: 1s;
    text-shadow: black 0px 0px 10px;
}
@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media only screen and (max-width: 600px) {
    .base h1 {
        top: 10%;
    }

    .base p {
        top: 6%;
    }
}
