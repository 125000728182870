#Projects {
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
        "Lucida Sans", Arial, sans-serif;
    background: #f1f1f1;
    overflow: hidden;
    padding-bottom: 5%;
}

.pDescrip {
    margin: 4%;
}

.projectItemList {
    margin: auto;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    flex-flow: row wrap;
}

.ProjectItemList > div {
    margin: auto;
    margin-top: 0%;
    width: 33vh;
    padding-top: 5%;
    padding-bottom: 5%;
}

.projectItem {
    margin: auto;
    width: 300px;
}

.voteImage {
    width: 110%;
}

.polarioImage {
    width: 100%;
}

.item {
    padding: 3em 3em 1em 3em;
    background-color: #ffffff;
    border-radius: 15px;
    text-align: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
}

.item header {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    margin: -3em 0 3em -3em;
    width: calc(100% + 6em);
    height: 12em;
    display: -moz-flex;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    -moz-align-items: center;
    -webkit-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -moz-justify-content: center;
    -webkit-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    background-color: #444;
    color: rgba(255, 255, 255, 0.75);
    position: relative;
    overflow: hidden;
}

.item header input,
.item header select,
.item header textarea {
    color: #ffffff;
}

.item header a {
    color: inherit;
}

.item header a:hover {
    color: #ef6480 !important;
}

.item header strong,
.item header b {
    color: #ffffff;
}

.item header h1,
.item header h2,
.item header h3,
.item header h4,
.item header h5,
.item header h6 {
    color: #ffffff;
}

.item header blockquote {
    border-left-color: #ffffff;
}

.item header code {
    background: rgba(255, 255, 255, 0.075);
    border-color: #ffffff;
}

.item header hr {
    border-bottom-color: #ffffff;
}

.item header a {
    -moz-transition: -moz-transform 0.2s ease;
    -webkit-transition: -webkit-transform 0.2s ease;
    -ms-transition: -ms-transform 0.2s ease;
    transition: transform 0.2s ease;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
}

.item header a:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    /* background-image: url("images/overlay.png"); */
}

.item header:hover a {
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

.item header > * {
    position: relative;
    z-index: 1;
}

.item header h3 {
    margin: 0;
    -moz-pointer-events: none;
    -webkit-pointer-events: none;
    -ms-pointer-events: none;
    pointer-events: none;
}

.items {
    display: -moz-flex;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: calc(100% + 2em);
    margin: -2em 0 0 -2em;
}

.items .item {
    margin: 2em 2em 0em 0em;
    width: calc(50% - 2em);
}

/* @media screen and (max-width: 980px) {

    .item header {
        height: 25em;
    }

    .items {
        -moz-flex-direction: column;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -moz-flex-wrap: nowrap;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        width: 100%;
        margin: auto;
    }

        .items .item {
            width: 100%;
            margin: auto;
        }

            .items .item:last-child {
                margin-bottom: 0;
            }

    .voteImage {
        width: 100%;
        height: 39vh;
    }

} */

/* @media screen and (max-width: 736px) {

   

    .items .item {
        margin: auto;
    }

} */

@media screen and (max-width: 980px) {
    #Projects h1 {
        margin-top: 20%;
    }
    .item {
        padding: 2em 1em 0.8em 1em;
    }

    .item header {
        height: 12em;
        margin: -2em 0 2em -1em;
        width: calc(100% + 2em);
    }
}
