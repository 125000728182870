#About {
    padding-bottom: 5%;
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
        "Lucida Sans", Arial, sans-serif;
    /* background: rgb(255,255,255); */
    /*background: linear-gradient(0deg, #f1f1f1 0%, #4467f3 80%);*/
    background-color: #f1f1f1;
    overflow: hidden;
}

#About::before {
    position: absolute;
    content: "";
    z-index: 0;
    top: 100vh;
    height: 90%;
    left: 280px;
    width: calc(100% - 280px);
    background-color: #4467f3;
    border-bottom-left-radius: 45% 1.5%;
    border-bottom-right-radius: 45% 1.5%;
    box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.2),
        0 6px 10px 0 rgba(0, 0, 0, 0.19);
}

.myInfo {
    position: relative;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
    margin: auto;
    width: 80%;
    background-color: #f1f1f1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
}

.myInfo > div {
    margin: auto;
    margin-top: 0%;
    width: 33vh;
    padding-top: 5%;
    padding-bottom: 5%;
}

.description {
    margin: 3%;
    color: white;
    font-size: large;
    /* text-shadow: black 0px 0px 2px; */
}

.AboutText h1 {
    margin-top: 4%;
    position: relative;
    color: white;
    /* text-shadow: black 0px 0px 4px; */
}

@media only screen and (max-width: 1100px) {
    .myInfo {
        flex-direction: column;
    }

    .myInfo > div {
        width: 100%;
        padding: 6%;
    }

    #About::before {
        height: 150%;
    }
}

@media only screen and (max-width: 600px) {
    .AboutText h1 {
        margin-top: 22%;
    }

    #About::before {
        left: 0%;
        width: 100%;
        height: 200%;
    }
}
