.slides {
    position: relative;
}

.container {
    display: flex;
    flex-direction: row;
}

.imageDiv {
    position: absolute;
    transform: translate(0, -100%);
}

.image {
    position: relative;
    width: 75%;
}

.image2 {
    position: relative;
    width: 42%;
}

.image3 {
    position: relative;
    width: 100%;
}

.image4 {
    position: relative;
    width: 45%;
}

.image5 {
    position: relative;
    width: 42%;
}

.image6 {
    position: relative;
    width: 75%;
}

.image7 {
    position: relative;
    width: 80%;
}

.dotContainer {
    margin: auto;
    position: relative;
    width: fit-content;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
}

.dotContainer > div {
    margin: 10px;
    margin-top: 10px;
}

.dot {
    width: 6px;
    height: 6px;
    background-color: rgba(61, 61, 61, 0.411);
    border-radius: 50%;
    transition: transform 0.5s ease, background-color 0.5s ease;
}

.dot.bigger {
    transform: scale(1.8);
    background-color: #fa8072;
}

.dot:hover {
    transform: scale(1.8);
}
