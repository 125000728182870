#Experience {
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
        "Lucida Sans", Arial, sans-serif;
    padding-top: 5%;
    overflow: hidden;
    background: linear-gradient(180deg, #f1f1f1 0%, #9fb1f9 100%);
    /* background-color: #f1f1f1; */

    
    /* background-color: #4467f3;
    border-top-left-radius: 45% 1.5%;
    border-top-right-radius: 45% 1.5%; */
    padding-bottom: 10%;
    /* padding-top: -10%; */
}

.accordion {
    margin: auto;
    width: 80%;
    display: none;  
}

.paperList {
    /* padding-top: 6%; */
    margin: auto;
    width: 70%;
}

.descrip {
    margin: 4%;
}

.toggleBar {
    margin: auto;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    flex-flow: row wrap;
}

.slideshow {
    margin: auto;
    width: 70%;
    padding-top: 5%;
    /* margin-top: 0; */
}

.experienceMain {
    padding-top: 4%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media only screen and (max-width: 1000px) {
    .experienceMain {
        flex-direction: column;
    }

    .paperList {
        width: 80%;
    }

    .slideshow {
        width: 80%;
    }
}

@media only screen and (max-width: 600px) {
    .accordion {
        display: block;
    }

    .slideshow {
        /* width: 80%; */
        display: none;
    }

    .paperList {
        display: none;
    }

    .toggleBar {
        display: none;
    }
}
